<template>
  <div id="admin-user-detail">
    <div class="title2">Kullanıcı Detay</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Anasayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Kullanıcılar</el-breadcrumb-item>
        <el-breadcrumb-item>Detay</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="buttons-group"></div>
    </div>

    <div class="main">
      <div class="profile">
        <div class="profile-top">
          <div class="profile-photo">
            <img v-if="userInfo.Image != null" :src="userInfo.Image" alt="" />
          </div>
          <div class="profile-name">
            {{
              userInfo.FirstName?.trim()
                .split(" ")
                .map((x) => x[0] + "***")
                .reduce((x, y) => x + " " + y, "")
            }}{{
              userInfo.LastName?.trim()
                .split(" ")
                .map((x) => x[0] + "***")
                .reduce((x, y) => x + " " + y, "")
            }}
          </div>
        </div>
        <div class="profile-bottom">
          <div class="item" v-if="userInfo.Address != null">
            <i class="icon-location"></i><span>{{ userInfo.Address }}</span>
          </div>
          <div class="item" v-if="userInfo.Email != null">
            <i class="icon-message"></i><span>{{ userInfo.Email }}</span>
          </div>
          <div class="item">
            <i class="icon-mobile"></i><span>{{ userInfo.PhoneNumber | VMask("+## ### ### ## ##") }}</span>
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="address">
          <p>Adres</p>
          <div class="info">
            <label class="data1">Adres</label>
            <label class="data2">{{ userInfo.InvoiceAddress.Address }}</label>
          </div>
          <div class="info">
            <label class="data1">İl</label>
            <label class="data2">{{ userInfo.InvoiceAddress.City }}</label>
          </div>
          <div class="info">
            <label class="data1">İlçe</label>
            <label class="data2" v-if="userInfo.InvoiceAddress != null">{{ userInfo.InvoiceAddress.District }}</label>
          </div>
          <div class="info">
            <label class="data1">Posta Kodu</label>
            <label class="data2">{{ userInfo.InvoiceAddress.ZipCode }}</label>
          </div>
        </div>
        <div class="last-conversations">
          <div class="title">
            <div>Geçmiş Görüşmeler ({{ userAppointments.length }})</div>
          </div>
          <div class="profile-slider owl-theme owl-carousel">
            <div class="slider-item" v-for="(item, index) in userAppointments.slice(0, 5)" :key="index">
              <div class="dr-card">
                <div class="dr-photo">
                  <img v-if="item.DoctorUser != null" :src="item.DoctorUser.Image" alt="" />
                </div>
                <div class="dr-information">
                  <div class="dr-name" v-if="item.DoctorUser != null">{{ item.DoctorUser.Title }}</div>
                  <div class="date">{{ $moment(item.StartDate).format("Do MMMM YYYY") }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="end" v-if="user.Role == 12">
        <div class="segment">
          <div class="title">Segment Seç</div>
          <el-select v-model="userInfo.SegmentId" placeholder="Segment Seçiniz..." class="select" v-if="true" clearable style="width: 100%" @change="changeSegmentation">
            <el-option v-for="(item, index) in segmentationList" :key="index" :label="item.Title" :value="item.ID"> </el-option>
          </el-select>
        </div>
        <div class="segment">
          <div class="title">Kurum Seç</div>

          <el-select v-model="userInfo.InstitutionId" placeholder="Kurum Seçiniz..." class="select" v-if="true" clearable style="width: 100%" @change="changeInstitution">
            <el-option v-for="(item, index) in institutionList" :key="index" :label="item.Title" :value="item.ID"> </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminUserDetail",
  data() {
    return {
      userInfo: {},
      userAppointments: {},
      screenWidth: 0,
      activeTab: 0,
      segmentationList: [],
      segment: null,
      institutionList: [],
      institution: {}, //
    };
  },
  components: {
    // CreditCardVue,
  },
  methods: {
    async getUser() {
      var res = await this.$client.post("/User/Get", {
        Id: this.$route.params.id,
      });
      if (!res.data.HasError) {
        this.userInfo = res.data.Data.User;
        this.userAppointments = res.data.Data.Appointments;
      } else {
        this.$router.push("/admin/kullanicilar");
      }
    },
    async getSegmentationList() {
      var res = await this.$client.post("/Segment/List", { SearchTerm: null, Page: 1, PageSize: 1000 });
      this.segmentationList = res.data.Data.Items;
    },
    async getInstitutionList() {
      var res = await this.$client.post("/Institution/List", { SearchTerm: null, Page: 1, PageSize: 1000 });
      this.institutionList = res.data.Data.Items;
      //console.log(this.institutionList);
    },
    async changeSegmentation() {
      if (this.segment != "") {
        this.segment = null;
      }
      var res = await this.$client.post("/User/ChangeSegment", { UserId: this.userInfo.ID, SegmentId: this.userInfo.SegmentId });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
      }
    },

    async changeInstitution() {
      // Eger bir kurum seçilmiş ise eski kurum adını yeni Institution.Title atıyoruz
      //seçilmemiş ise dokunmuyoruz
      if (this.userInfo.InstitutionId != null && this.userInfo.InstitutionId != "") {
        this.institution = this.institutionList.find((o) => {
          return o.ID === this.userInfo.InstitutionId;
        });
        if (this.institution != null) {
          this.userInfo.Kurum = this.institution.Title;
        } else {
          this.userInfo.Kurum = null;
        }
      } else {
        this.userInfo.Kurum = null;
      }
      var res = await this.$client.post("/User/ChangeInstitution", {
        UserId: this.userInfo.ID,
        InstitutionId: this.userInfo.InstitutionId,
        Kurum: this.userInfo.Kurum,
      });
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
      }
    },
  },
  computed: {
    genderEnums() {
      return this.$store.getters.getEnums.find((x) => x.Title == "Gender");
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  async mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });

    await this.getSegmentationList();
    await this.getInstitutionList();
  },
  async beforeMount() {
    await this.getUser();
    window.jQuery(document).ready(function () {
      window.jQuery(".profile-slider").owlCarousel({
        items: 1,
        margin: 30,
      });
    });
  },
};
</script>

<style lang="less">
#admin-user-detail {
  padding: 42px 30px;

  .title2 {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #eaedf0;
        border: none;

        i {
          background: #44566c;
        }
      }
    }
  }

  .el-select__caret {
    background-color: #f8fafb;
    color: #b3c0ce;
  }

  .profile-settings-dialog {
    .el-dialog {
      border-radius: 10px;
      overflow: hidden;

      .el-dialog__header {
        display: none;
      }

      .el-dialog__body {
        padding: 0;
      }
    }
  }

  p {
    font-weight: 700;
    font-size: 30px;
    color: #44566c;
    margin: 0;
  }

  .main {
    display: flex;
    gap: 30px;

    @media screen and (max-width: 991px) {
      flex-direction: column;

      .profile,
      .middle,
      .end {
        width: 100% !important;
      }
    }

    .profile-name {
      font-weight: 700;
      font-size: 30px;
      line-height: 35px;
      color: #44566c;
      margin-top: 0;
      text-align: center;
    }

    .profile {
      width: 24.5%;
      height: 566px;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      background-color: #fff;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
    }

    .profile-top {
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }

    .profile-bottom {
      background-color: #f8fafb;
      padding: 30px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 0.4rem;
        align-items: center;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: #44566c;

        & > :last-child {
          min-width: 150px;
        }
      }

      i {
        color: #8697ab;
      }
    }

    .profile-photo {
      width: 220px;
      height: 220px;
      border-radius: 40px;
      overflow: hidden;
      box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.08);

      img {
        width: 100%;
      }
    }

    .edit {
      background: #234a98;
      border-radius: 50px;
      box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);

      span {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 0.4rem;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #fff;
        border: none;
      }
    }
  }

  .middle {
    width: 37.75%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;

    .address {
      background-color: #fff;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      padding: 30px;
      gap: 1rem;

      p {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #44566c;
      }

      .info {
        display: flex;
        justify-content: space-between;
      }
    }

    .icon-task {
      width: 22px;
      height: 22px;
      background-color: #44566c;
      float: right;
    }

    .data1 {
      font-weight: 400;
      font-size: 15px;
      color: #8697a8;
    }

    .data2 {
      color: #44566c;
      font-weight: 500;
      font-size: 15px;
    }

    .last-conversations {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;

        a {
          font-weight: 400;
          font-size: 15px;
          text-decoration: none;
          color: #234a98;
        }

        div {
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #44566c;
        }
      }

      height: 299px;
      background-color: #fff;
      box-shadow: 1px 4px 24px rgba(169, 194, 209, 0.4), 0px 2px 16px rgba(169, 194, 209, 0.01);
      border-radius: 15px;
      padding: 25px;

      .dr-card {
        overflow: hidden;
        background-color: #f8fafb;
        border-radius: 15px;
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
      }

      .dr-photo {
        flex-shrink: 0;
        background-color: #8697a8;
        width: 148px;
        height: 183px;
        border-radius: 15px 0 0 15px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .dr-information {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 25px;
        width: 100%;

        .date {
          margin-top: 5px;
          font-weight: 500;
          font-size: 15px;
          line-height: 24px;

          color: #44566c;
          margin-bottom: 30px;
        }

        .detail {
          background-color: #234a98;
          width: 137px;
          height: 40px;
          color: #fff;
          font-weight: 500;
          border-radius: 5px;
          font-size: 15px;
        }
      }

      i {
        float: left;
      }
    }
  }

  .end {
    width: 37.75%;
    display: flex;
    gap: 30px;
  }

  .dialog {
    display: flex;

    @media screen and (max-width: 991px) {
      flex-direction: column;

      .dialog-left,
      .dialog-right {
        width: 100% !important;
      }

      .dialog-left {
        .dialog-left-bottom {
          ul {
            margin: 0;
            flex-direction: row;

            li {
              flex-direction: column;
              height: auto;
            }
          }
        }
      }
    }

    .dialog-left-bottom {
      background: #f8fafb;
      padding-left: 8px;

      ul {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0;
      }

      li {
        cursor: pointer;
        display: flex;
        list-style: none;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 100%;

        &.active {
          background-color: white;
        }

        height: 56px;

        div {
          font-weight: 500;
          font-size: 17px;
          color: #44566c;
        }
      }
    }

    .dialog-left {
      width: 40%;
      display: flex;
      flex-direction: column;
      background-color: #f8fafb;
    }

    .dialog-left-top {
      padding-top: 2rem;
      display: flex;
      align-self: center;
      margin-bottom: 55px;

      .image {
        cursor: pointer;
        width: 170px;
        background-position: center;
        background-size: cover !important;
        height: 170px;
        background: #44566c;
        opacity: 0.6;
        border-radius: 40px;
        box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
        display: flex;
        flex-direction: column;
        justify-content: center;

        div,
        i {
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          align-self: center;
        }
      }
    }

    .dialog-right {
      padding: 1rem;
      width: 60%;
      display: flex;
      flex-direction: column;

      .container {
        width: 100%;

        .el-input {
          input {
            width: 100%;
          }
        }

        div {
          //display: flex;
          i {
            align-content: center;
            margin-left: 5px;
          }
        }

        .dt-container {
          display: flex;
          gap: 15px;

          .el-select {
            width: 33.333%;

            input {
              width: 100%;
            }
          }
        }
      }
    }

    .image {
      width: 170px;
      height: 170px;

      opacity: 0.6;
      border-radius: 40px;
      box-shadow: 0px 16px 32px rgba(169, 194, 209, 0.55), 0px 8px 16px rgba(169, 194, 209, 0.45);
      display: flex;
      flex-direction: column;

      div,
      i {
        color: #fff;
        font-weight: 500;
        font-size: 15px;
        align-self: center;
      }
    }

    .container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-self: center;

      input,
      select {
        background-color: #f8fafb;
        width: 280px;
        height: 42.22px;
        border: none;
      }

      div {
        color: #8697a8;
        font-weight: 400;
        font-size: 15px;
      }

      .title2 {
        font-weight: 700;
        font-size: 30px;
        color: #44566c;
        padding-top: 15px;
        margin-bottom: 15px;
      }

      .save-button {
        background-color: #234a98;
        color: #fff;
        width: 116px;
        height: 40px;
        font-weight: 500;
        font-size: 15px;
        align-self: flex-end;
        margin-top: 10px;

        i {
          float: left;
        }
      }
    }
  }

  .segment {
    width: 100%;
    height: 566px;
    box-shadow: 1px 4px 24px rgb(169 194 209 / 40%), 0px 2px 16px rgb(169 194 209 / 1%);
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 30px;

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #44566c;
    }
  }

  .select {
    .el-input__inner {
      z-index: 1;
      border: none;
      background-color: #eaedf0;
      border-radius: 5px;
      padding: 15px;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
    }

    .el-select__caret {
      background-color: unset !important;
    }
  }
}
</style>
